@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #e6dacc;
}

.pb-shadow {
  text-shadow: 1px 1px 0.5px rgba(135, 97, 86, 1);
}
.pw-shadow {
  text-shadow: -2px -0.4px 0.4px rgba(255, 255, 255, 1);
}
div .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #876156;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.carousel {
  text-align: center;
}

.fa-stack[data-count]:after {
  position: absolute;
  right: 1%;
  top: 2%;
  content: attr(data-count);
  font-size: 40%;
  padding: 0.6em;
  border-radius: 999px;
  border-color: #876156;
  line-height: 0.75em;
  color: rgb(133, 74, 74);
  /* background:rgba(158, 101, 101, 0.85); */
  background: white;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}
.grid-container {
  text-align: center;
  align-self: center;
  left: 169px;
  /* float: c; */
  clear: both;
}
.item_box {
  /* align-content: center; */
  float: ;
}
.successMessage {
  align-items: center;
  justify-content: center;
  padding-top: 15vh;
  padding: auto;
  padding-left: 8vh;
}
.successPopup{
  background-color: blue;
  
}
.tick {
  position: absolute;
  /* align-content: first baseline; */
  top: 4cm;
  width: 230px;
}

.occasionType {
  /* background-color: rgba(218, 189, 153, 0.55); */
  /* overla */
  background: rgba(233, 140, 0, 0.7);
  /* margin-top: 10px; */
  /* margin-left: 55px; */
  /* width: 200px; */
  font-size: 25px ;
  /* color: rgb(252, 80, 0); */
  /* position: absolute; */
}